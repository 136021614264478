import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

const Seo = ({ 
  title, 
  description, 
  keywords, 
  image: metaImage, 
  pathname, 
  meta, 
  schema,
  children 
}) => {
  const { site, sanitySiteSettings } = useStaticQuery(graphql`
    query SeoComponentQuery {
      site {
        siteMetadata {
          siteUrl
          title
          description
        }
      }
      sanitySiteSettings {
        title
        description
      }
    }
  `)

  const siteTitle = sanitySiteSettings?.title || site?.title
  const image = metaImage && metaImage.src ? metaImage.src : null
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  // console.log('siteTitle:', siteTitle)
  // console.log('title:', title)
  // console.log('description:', description)
  // console.log('keywords:', keywords)
  // console.log('image:', image)
  // console.log('pathname:', pathname)
  // console.log('meta:', meta)
  // console.log('schema:', schema)

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en-ZA',
      }}
      title={title}
      defaultTitle={siteTitle}
      titleTemplate={`%s | ${siteTitle}`}
      link={
        canonical ? [
          {
            rel: "canonical",
            href: canonical
          }
        ] : []
      }
      meta={[
        {
          name: 'description',
          content: description
        },
        {
          name: 'keywords',
          content: keywords?.join(',') || ''
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description
        }
      ]
      .concat(metaImage ? [
        {
          property: "og:image",
          content: image,
        },
        {
          property: "og:image:width",
          content: metaImage.width
        },
        {
          property: "og:image:height",
          content: metaImage.height
        },
        {
          name: "twitter:card",
          content: "summary_large_image"
        }
      ] : [{
        name: `twitter:card`,
        content: `summary`
      }])
      .concat(meta)}
    >
      {schema && (
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      )}
      {children}
    </Helmet>
  )
}

Seo.defaultProps = {
  meta: [],
  description: ``,
}

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
  schema: PropTypes.object,
}

export default Seo