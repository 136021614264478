import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import SubLayout from '../components/layouts/SubLayout'
import Seo from '../components/Seo'

const isValidCategory = (categoryName) => {
  return categoryName === 'Campus News' || categoryName === 'Career & Study Advice' || categoryName === 'Student Success Stories'
}

const NewsPage = ({ data }) => {
  let articles = data.allSanityArticle.nodes

  const articleCategory = typeof window !== 'undefined' ? new URLSearchParams(window.location.search).get('category') : ''
  const validCategory = isValidCategory(articleCategory)

  if (validCategory) {
    articles = articles.filter(article => article.category === articleCategory)
  }

  const articleElements = articles.map(({title, slug, featuredImage, _rawContent}) => (
    <Link key={slug.current} to={`/${slug.current}/`}>
      <article>
        <GatsbyImage image={getImage(featuredImage.asset)} alt={slug.current} />
        <h3>{title}</h3>
      </article>
    </Link>
  ))

  return (
    <SubLayout>
      <Seo title='News & Reviews' pathname='/news' />
      <StyledNewsPage>
        <h1>{validCategory ? articleCategory : 'News & Reviews'}</h1>

        <p className="breadcrumbs">
          <Link to='/'>Home</Link> {`>`}&nbsp;
          <Link to='/news/'>News & Reviews</Link>
          {validCategory && ` > ${articleCategory}`}
        </p>

        <section id="article-cards">
          {articleElements}
        </section>
      </StyledNewsPage>
    </SubLayout>
  )
}

export const query = graphql`
  query NewsPageQuery {
    allSanityArticle(sort: {fields: _createdAt, order: DESC}) {
      nodes {
        title
        slug { current }
        category
        featuredImage {
          asset { gatsbyImageData(aspectRatio: 1.5, layout: FULL_WIDTH) }
        }
        _rawContent
      }
    }
  }
`

const StyledNewsPage = styled.div`
  h1 {
    color: #333;
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }

  section#article-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    grid-gap: 2rem;

    a {
      text-decoration: none;
    }

    h3 {
      margin-top: 1rem;
    }
  }
`

export default NewsPage
